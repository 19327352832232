/*Desktop (1680*1050) Laptop (1280*800)*/
.nav-menu {
  height: 40px;
  background-color: #403B37;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.nav-menu ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-menu li {
  font-family: 'Roboto Serif';
  color: #ffffff;
  font-size: 23px;
  margin: 15px;
  cursor: pointer;
}

.nav-menu li:hover {
  color: #B5B4B2;
}

.nav-menu li:last-child {
  background-color: #B5B4B2;
  border: 2px solid #B5B4B2;
  padding: 4px 12px;
  border-radius: 06px;
  color: #403B37;
}

.nav-menu li:last-child:hover {
  background-color: transparent;
  border: 2px solid #B5B4B2;
  color: #ffffff;
}

.icon-hbg {
  width: 50px;
  height: 50px;
  border-radius: 2px;
  color: #B5B4B2
}

.menu-icon,
#check {
  display: none;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .nav-menu li {
    font-size: 18px;
    margin: 13px;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:700px) and (max-width:767px) {
  .nav-menu {
    height: 25px;
    justify-content: flex-end;
    align-items: center;
  }

  .checkbtn {
    display: block;
    position: absolute;
  }

  .menu-icon {
    display: flex;
    width: 30px;
    margin-right: 25px;
  }

  .nav-menu ul {
    display: block;
    position: absolute;
    top: 130px;
    left: -100%;
    background: #403B37;
    width: 30%;
    height: 45vh;
    right: 0;
  }

  .nav-menu ul li {
    padding: 15px;
    display: flex;
    justify-content: center;
    margin: 0;
    font-size: 15px;
  }

  .nav-menu li:last-child {
    background: none;
    border: none;
    color: #ffffff;
  }

  .nav-menu li:last-child:hover {
    background: none;
    border: none;
    color: #B5B4B2;
  }

  #check:checked~ul {
    left: auto;
  }
}

/*Smartphones (320*480)*/
@media only screen and (min-width:480px) and (max-width:699px) {
  .nav-menu {
    height: 20px;
    justify-content: flex-end;
    align-items: center;
  }

  .checkbtn {
    display: block;
    position: absolute;
  }

  .menu-icon {
    display: flex;
    width: 25px;
    margin-right: 25px;
  }

  .nav-menu ul {
    display: block;
    position: absolute;
    top: 110px;
    left: -100%;
    background: #403B37;
    width: 30%;
    height: 40vh;
    right: 0;
  }

  .nav-menu ul li {
    padding: 12px;
    display: flex;
    justify-content: center;
    margin: 0;
    font-size: 12px;
  }

  .nav-menu li:last-child {
    background: none;
    border: none;
    color: #ffffff;
  }

  .nav-menu li:last-child:hover {
    background: none;
    border: none;
    color: #B5B4B2;
  }

  #check:checked~ul {
    left: auto;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:480px) {
  .nav-menu {
    height: 20px;
    justify-content: flex-end;
    align-items: center;
  }

  .checkbtn {
    display: block;
    position: absolute;
  }

  .menu-icon {
    display: flex;
    width: 25px;
    margin-right: 25px;
  }

  .nav-menu ul {
    display: block;
    position: absolute;
    top: 90px;
    left: -100%;
    background: #403B37;
    width: 30%;
    height: 40vh;
    right: 0;
  }

  .nav-menu ul li {
    padding: 12px;
    display: flex;
    justify-content: center;
    margin: 0;
    font-size: 12px;
  }

  .nav-menu li:last-child {
    background: none;
    border: none;
    color: #ffffff;
  }

  .nav-menu li:last-child:hover {
    background: none;
    border: none;
    color: #B5B4B2;
  }

  #check:checked~ul {
    left: auto;
  }
}