/*Desktop (1680*1050) Laptop (1280*800)*/
.container-section {
  font-family: 'Roboto Serif';
  color: #403B37;
}

.title-section {
  font-size: 30px;
  margin-left: 20px;
}

hr {
  height: 1px;
  width: 250px;
  margin-left: 20px;
  margin-bottom: 10px;
  background-color: #A30000;
}

.container-info {
  width: 97%;
  margin: auto;
}

.container-mini {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 0.5fr);
  grid-gap: 20px;
}

.iconDisable {
  pointer-events: none;
}

.imgSingle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.item1 {
  cursor: pointer;
}

.title-image {
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}

.go-to {
  font-family: 'Roboto Serif';
  font-size: 25px;
  text-decoration: underline;
  text-align: end;
  cursor: pointer;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .title-section {
    font-size: 25px;
  }

  hr {
    width: 250px;
  }

  .container-info {
    width: 95%;
  }

  .container-mini {
    grid-template-columns: repeat(3, 1fr);

  }

  .item4 {
    display: none
  }

  .go-to {
    font-size: 20px;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .title-section {
    font-size: 20px;
  }

  hr {
    width: 200px;
  }

  .container-info {
    width: 95%;

  }

  .container-mini {
    grid-template-columns: repeat(2, 1fr);

  }

  .iconDisable {
    grid-template-columns: repeat(3, 1fr);
  }

  .item3 {
    display: none
  }

  .item4 {
    display: none
  }

  .go-to {
    font-size: 15px;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .title-section {
    font-size: 18px;
    margin-left: 15px;
  }

  hr {
    width: 150px;
    margin-left: 15px;

  }

  .container-info {
    width: 95%;

  }

  .container-mini {
    grid-template-columns: 1fr;
  }

  .iconDisable {
    grid-template-columns: repeat(3, 1fr);
  }

  .item2,
  .item3,
  .item4 {
    display: none;
  }

  .go-to {
    font-size: 15px;
  }
}