/*Desktop (1680*1050) Laptop (1280*800)*/
.newsContainer {
  border-radius: 10px;
  width: 98%;
  margin: 20px auto;
}

.newsTitle {
  font-size: 30px;
  font-family: 'Roboto Serif';
  color: #403B37;
}

.lineNews {
  height: 1px;
  width: 250px;
  margin-left: -0px;
  background-color: #A30000;
}

.container01 {
  display: grid;
  grid-template-columns: repeat(2, 80% 20%);
}

.container02 {
  margin: auto;
}

.blc01,
.blc02,
.blc03 {
  background-color: #fff;
  border: 4px double #403B37;
  border-radius: 10px;
  margin: 0 10px 10px 0;
  padding: 10px;
}

.blc03 {
  display: block;
  text-align: center;
}

.pinNews {
  font-size: 13px;
  font-weight: bold;
}

.sentenceNews {
  font-size: 17px;
  text-align: justify;
}

.textMail {
  font-size: 20px;
  margin-bottom: 10px;
}

.iconMail {
  width: 50%;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:1024px) {
  .newsTitle {
    font-size: 25px;
  }

  .pinNews {
    font-size: 11px;
  }

  .sentenceNews {
    font-size: 15px;
  }

  .textMail {
    font-size: 18px;
  }

}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .newsTitle {
    font-size: 20px;
  }

  .pinNews {
    font-size: 09px;
  }

  .sentenceNews {
    font-size: 13px;
  }

  .textMail {
    font-size: 13px;
  }

}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .container01 {
    display: block;
  }

  .newsTitle {
    font-size: 18px;
  }

  .pinNews {
    font-size: 07px;
  }

  .sentenceNews {
    font-size: 11px;
  }

  .textMail {
    font-size: 15px;
  }

  .iconMail {
    width: 30%;
  }

}