/*Desktop (1680*1050) Laptop (1280*800)*/
.containerBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.containerSlides {
  width: 40%;
  margin-bottom: 50px;
}

.tittleSlide {
  text-align: center;
  font-size: 20px;
}

.wrappVideo {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {

  .tittleSlide {
    font-size: 15px;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .containerSlides {
    width: 80%;
  }

  .tittleSlide {
    font-size: 12px;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .containerSlides {
    width: 80%;
  }

  .tittleSlide {
    font-size: 10px;
  }
}