/*Desktop (1680*1050) Laptop (1280*800) (min-width:925px) and (max-width:1680px)*/
.contGallery {
  border: 4px double #403B37;
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto 40px;
  max-width: 90%;
}

.titleGallery {
  font-family: 'Roboto Serif';
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 10px;
}

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 100%;

}

.galleryWrap .single {
  width: 10%;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 150;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
  height: 50px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  height: 50px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  height: 50px;
}

.fullScreenImage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 95%;
  max-height: 95%;
  pointer-events: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.videosCont {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-top: 10px;
}

.singleVideo {
  border: none;
  width: 30%;
  height: 350px;
}

@media only screen and (min-width:768px) and (max-width:924px) {
  .contGallery {
    margin: 0 auto 30px;
  }

  .titleGallery {
    font-size: 1.1rem;
  }

  .galleryWrap .single {
    width: 30%;
  }

  .sliderWrap .btnClose {
    right: 5px;
    height: 40px;
  }

  .sliderWrap .btnPrev {
    left: 5px;
    height: 40px;
  }

  .sliderWrap .btnNext {
    right: 5px;
    height: 40px;
  }

  .fullScreenImage {
    width: 99%;
    height: 99%;

  }
}

@media only screen and (min-width:480px) and (max-width:767px) {
  .contGallery {
    margin: 0 auto 30px;
  }

  .titleGallery {
    font-size: .9rem;
  }

  .galleryWrap .single {
    width: 30%;
  }

  .sliderWrap .btnClose {
    right: 5px;
    height: 30px;
  }

  .sliderWrap .btnPrev {
    left: 5px;
    height: 30px;
  }

  .sliderWrap .btnNext {
    right: 5px;
    height: 30px;
  }

  .fullScreenImage {
    width: 90%;
    height: 99%;
    margin-left: 25px;
  }

  .singleVideo {
    border: none;
    width: 45%;
    height: 300px;
  }
}

@media only screen and (max-width:479px) {
  .contGallery {
    margin: 0 auto 15px;
  }

  .titleGallery {
    font-size: .7rem;
  }

  .galleryWrap .single {
    width: 44%;
  }

  .sliderWrap .btnClose {
    top: 20%;
    right: 50px;
    height: 30px;
  }

  .sliderWrap .btnPrev {
    top: 70%;
    left: 50px;
    height: 30px;
  }

  .sliderWrap .btnNext {
    top: 70%;
    right: 50px;
    height: 30px;
  }

  .fullScreenImage {
    width: 90%;
    height: 99%;
    margin: auto;
  }

  .singleVideo {
    border: none;
    width: 100%;
    height: 300px;
  }
}