/*Desktop (1680*1050) Laptop (1280*800)
(min-width:925px) and (max-width:1680px)*/
.contactContainer {
  margin-bottom: 5%;
}

.titleExt {
  font-family: 'Roboto Serif';
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.blockContainerContact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.network {
  text-align: center;
}

.textDirection {
  margin-right: 20px;
  font-size: 1.3rem;
}

.mapDirection {
  border-style: outset;
  width: 200px;
  height: 200px;
}

.blockContainerContact2 {
  display: grid;
  grid-template-columns: .5fr 1fr;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.textPhones {
  font-size: 1.3rem;
  text-align: right;
}

.areaLocation {
  font-size: 1.3rem;
  text-align: left;
}

.splitter {
  color: #A30000;
  height: 1px;
  width: 75%;
  margin: 20px auto;

}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .contactContainer {
    margin-bottom: 4%;
  }

  .titleExt {
    font-size: 1.3rem;
  }

  .textDirection {
    margin-right: 20px;
    font-size: 1.1rem;
  }

  .mapDirection {
    width: 150px;
    height: 150px;
  }

  .textPhones {
    font-size: 1.1rem;
  }

  .areaLocation {
    font-size: 1.1rem;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .contactContainer {
    margin-bottom: 3%;
  }

  .titleExt {
    font-size: 1.1rem;
  }

  .textDirection {
    margin-right: 20px;
    font-size: .9rem;
  }

  .mapDirection {
    width: 100px;
    height: 100px;
  }

  .textPhones {
    font-size: .9rem;
  }

  .areaLocation {
    font-size: .9rem;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .contactContainer {
    margin-bottom: 3%;
  }

  .titleExt {
    font-size: .9rem;
  }

  .textDirection {
    margin-right: 20px;
    font-size: .7rem;
  }

  .mapDirection {
    width: 100px;
    height: 100px;
  }

  .textPhones {
    font-size: .7rem;
  }

  .areaLocation {
    font-size: .7rem;
  }
}