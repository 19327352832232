/*Desktop (1680*1050) Laptop (1280*800)*/
.container-tittle {
  font-family: 'Roboto Serif';
  display: grid;
  grid-template-columns: .8fr 3.2fr;
  align-items: center;
}

.logo-snt {
  width: 100px;
  height: 120px;
  margin: auto;
}

.tittlePrincipal {
  font-size: 2.5rem;
  font-weight: bold;
}

.tittleSecondary {
  font-size: 1.5rem;
}

/*Tablets (700*1024 o 1024*700)*/
@media only screen and (min-width:700px) and (max-width:924px) {
  .logo-snt {
    width: 80px;
    height: 100px;
  }

  .tittlePrincipal {
    font-size: 1.8rem;
  }

  .tittleSecondary {
    font-size: 1.2rem;
  }
}

/*Smartphones (699*480)*/
@media only screen and (min-width:480px) and (max-width:699px) {
  .logo-snt {
    width: 60px;
    height: 80px;
  }

  .tittlePrincipal {
    font-size: 1.2rem;
  }

  .tittleSecondary {
    font-size: .8rem;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .logo-snt {
    width: 40px;
    height: 60px;
  }

  .tittlePrincipal {
    font-size: .8rem;
  }

  .tittleSecondary {
    font-size: .7rem;
  }
}