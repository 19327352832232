/*Desktop (1680*1050) Laptop (1280*800)*/
.nav-menucls {
  height: 30px;
  background-color: #403B37;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
}

.icon-close {
  width: 40px;
  height: 30px;
  color: #B5B4B2;
  margin-right: 30px;
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .nav-menucls {
    height: 25px;
  }

  .icon-close {
    height: 25px;
    margin-right: 20px;

  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .nav-menucls {
    height: 20px;
  }

  .icon-close {
    height: 20px;
    margin-right: 10px;

  }
}