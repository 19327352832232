/*Desktop (1680*1050) Laptop (1280*800)
(min-width:925px) and (max-width:1680px)*/
.footerContainer {
  bottom: 0;
  background-color: #403B37;
  font-family: 'Roboto Serif';
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
}

.footerTitle {
  margin-bottom: 10px;
}

.containerLinks {
  display: flex;
  justify-content: center;

}

.containerBlock {
  width: 20%;
}

.footerLink {
  color: #ffffff;
  text-decoration: none;
}

.registration {
  font-family: 'Roboto';
  text-align: center;
  font-size: .8rem;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .footerContainer {
    font-size: .8rem;
  }

  .registration {
    font-size: .6rem;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .footerContainer {
    font-size: .7rem;
  }

  .registration {
    font-size: .5rem;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .footerContainer {
    font-size: .6rem;
  }

  .registration {
    font-size: .4rem;
  }
}