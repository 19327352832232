/*Desktop (1680*1050) Laptop (1280*800)*/
.containerReleases {
  margin-bottom: 50px;
}

.miniReleases {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  margin: auto;
}

.release {
  height: 75%;
}

.titleRelease {
  text-align: center;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .miniReleases {
    grid-template-columns: repeat(3, 1fr);
  }

  .release {
    margin-bottom: 40px;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .miniReleases {
    grid-template-columns: repeat(2, 1fr);
  }

  .release {
    margin-bottom: 30px;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .containerReleases {
    margin-bottom: 0;
  }

  .miniReleases {
    display: block;
  }

  .release {
    margin-bottom: 20px;
  }
}