/*Desktop (1680*1050) Laptop (1280*800)
(min-width:925px) and (max-width:1680px)*/
.containerHistory {
  border: 4px double #403B37;
  border-radius: 20px;
  background-color: #fff;
  margin: 20px;
  padding: 20px;
}

.titleHistory {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
}

.textHistory {
  text-align: justify;
  font-size: 17px;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .titleHistory {
    font-size: 17px;
  }

  .textHistory {
    font-size: 14px;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .titleHistory {
    font-size: 14px;
  }

  .textHistory {
    font-size: 12px;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .containerHistory {
    border-radius: 20px;
    margin: 20px 10px;
    padding: 10px;
  }

  .titleHistory {
    font-size: 12px;
  }

  .textHistory {
    text-align: justify;
    font-size: 11px;
  }
}