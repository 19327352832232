/*Desktop (1680*1050) Laptop (1280*800)*/
.setHeading {
  background-color: #e1e1e0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
}

.setMain {
  margin-top: 180px;
}


/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:700px) and (max-width:924px) {
  .setMain {
    margin-top: 160px;
  }
}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:699px) {
  .setMain {
    margin-top: 120px;
  }
}

/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .setMain {
    margin-top: 100px;
  }
}