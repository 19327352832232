/*Desktop (1680*1050) Laptop (1280*800)*/
.containerPdf {
  font-family: 'Roboto', sans-serif;
  width: 98%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;

}

.formPdf {
  display: flex;
  flex-direction: column;
}

.titlePdf {
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

/*Tablets (768*1024 o 1024*768)*/
@media only screen and (min-width:768px) and (max-width:924px) {
  .containerPdf {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }

}

/*Smartphones (767*480)*/
@media only screen and (min-width:480px) and (max-width:767px) {
  .containerPdf {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
}


/*Smartphones (320*480)*/
@media only screen and (max-width:479px) {
  .containerPdf {
    display: block;
    width: 80%;
    margin-bottom: 40px;

  }

  .formPdf {
    margin-bottom: 50px;
  }
}