/*Desktop (1680*1050) Laptop (1280*800)*/
.img-home {
  border-radius: 8px;
  width: 90%;
  height: 90%;
  margin-left: 5%;
}

.img-gallery {
  border-radius: 8px;
  width: 90%;
  height: 90%;
  margin: auto;
}

.imgMap {
  border-radius: 8px;
  width: 70%;
  height: 70%;
  pointer-events: all;
}

.imgIcon {
  width: 50%;
  pointer-events: all;
}